import { SearchBoxProps } from "./interface";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@icons/Search";
import useStyles from "./styles";
import { CloseRounded } from "@mui/icons-material";

const SearchBox: React.FC<SearchBoxProps> = (props) => {
  const { value } = props;
  const classes = useStyles();

  const onChange = ({ target: { value = "" } }) => {
    props.onChange(value);
  };

  const onDelete = () => {
    props.onChange("");
  };

  return (
    <InputBase
      {...props}
      className={classes.root}
      inputProps={{ "data-testid": "searchbox" }}
      onChange={onChange}
      value={value}
      startAdornment={<SearchIcon className={classes.icon} />}
      endAdornment={
        value && (
          <CloseRounded
            data-testid="search-deleteIcon"
            fontSize="small"
            className={classes.deleteIcon}
            onClick={onDelete}
          />
        )
      }
    />
  );
};

export default SearchBox;
