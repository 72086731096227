export const popularSearchDev = [
  {
    name: "IoT Platform",
    url: "internet-of-things/iot-platform",
  },
  {
    name: "ASTINet Dedicated Internet",
    url: "digital-connectivity-services/dedicated-internet-astinet",
  },
  {
    name: "Metro Ethernet",
    url: "digital-connectivity-services/metro-ethernet",
  },
  { name: "Digital Advertising", url: "digital-advertising" },
  { name: "Big Data Platform", url: "big-data/big-data-platform" },
];

export const popularSearchStaging = [
  {
    name: "IoT Platform",
    url: "https://staging.mytens.co.id/internet-of-things/iot-platform",
  },
  {
    name: "ASTINet Dedicated Internet",
    url: "https://staging.mytens.co.id/digital-connectivity-services/dedicated-internet-astinet",
  },
  {
    name: "Metro Ethernet",
    url: "https://staging.mytens.co.id/digital-connectivity-services/metro-ethernet",
  },
  { name: "Digital Advertising", url: "https://staging.mytens.co.id/digital-advertising" },
  { name: "Big Data Platform", url: "https://staging.mytens.co.id/big-data/big-data-platform" },
];

export default popularSearchDev;
