import { useEffect, useState } from "react";
import { useRouter } from "next/router";

export const useLanguage = () => {
  const router = useRouter();
  const [language, setLanguage] = useState(router?.locale ?? "id");

  useEffect(() => {
    if (router) {
      const lang = `${router.locale}`;
      setLanguage(lang);
    }
  }, [router]);

  return {
    language,
    setLanguage,
  };
};
