import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import useResponsive from "@helpers/useResponsive";
// import { setOpenSnackbar } from "@redux/slices/rootSnackbar";
import { NAVBAR_API } from "@repositories/navbar";
import { useAuth } from "@utils/useAuth";
import { useLanguage } from "@utils/useLanguage";
import { IConfirmation } from "@atoms/ConfirmationDialog/interface";
import { useGetProductMenuQuery } from "@libraries/repositories/home/home.redux";

function useAction() {
  // const [dataNavbar, setDataNavbar] = useState([]);
  const [businessSolution, setBusinessSolution] = useState([]);
  const [loadNavbar, setLoadNavbar] = useState<boolean>(false);
  // const dispatch = useDispatch();
  const { language, setLanguage } = useLanguage();
  const { loginData: getStorage } = useAuth();

  const [search, setSearch] = useState("");
  const [confirmation, setConfirmation] = useState<IConfirmation>({ actions: [], content: "" });

  const lgClient = useResponsive("lg");
  const mdClient = useResponsive("md");

  const clearConfirmation = () => setConfirmation({ actions: [], content: "" });

  const { data: dataNavbar } = useGetProductMenuQuery(undefined);

  // const getNavbarMenus = async () => {
  //   try {
  //     setLoadNavbar(true);
  //     const { data, success } = await NAVBAR_API.GET_PRODUCT_MENU();
  //     if (success) {
  //       setDataNavbar(data);
  //       setLoadNavbar(false);
  //     }
  //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   } catch (err: any) {
  //     setLoadNavbar(false);
  //     dispatch(
  //       setOpenSnackbar({
  //         message: err?.message,
  //         variant: "failed",
  //       }),
  //     );
  //   }
  // };

  const getBusinessSolution = async () => {
    try {
      setLoadNavbar(true);
      const { data, success } = await NAVBAR_API.GET_BUSINESS_SOLUTION_MENU();
      if (success) {
        setBusinessSolution(data);
        setLoadNavbar(false);
      }
    } catch (err) {
      setLoadNavbar(false);
    }
  };

  useEffect(() => {
    // getNavbarMenus();
    getBusinessSolution();
  }, []);

  return {
    language,
    setLanguage,
    mdClient,
    lgClient,
    getStorage,
    search,
    setSearch,
    dataNavbar: dataNavbar?.data || [],
    loadNavbar,
    businessSolution,
    confirmation,
    setConfirmation,
    clearConfirmation,
  };
}

export default useAction;
