import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const IconClose = (props: IconProps) => {
  return (
    <SvgIcon
      {...props}
      width={35}
      height={35}
      viewBox="0 0 36 36"
      sx={{ fill: "none" }}
    >
      <circle cx="18" cy="18" r="18" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 19.4892L12.3285 25.1607C12.1276 25.3616 11.8555 25.4746 11.5709 25.4746H11.5708C11.1383 25.4746 10.7477 25.2137 10.5816 24.8133C10.4156 24.4128 10.5077 23.9524 10.8133 23.6455L16.4848 17.974L10.8133 12.3024C10.4198 11.8797 10.4324 11.2226 10.8398 10.8138C11.2486 10.4064 11.9058 10.3938 12.3285 10.7872L18 16.4587L23.6715 10.7873C24.0942 10.3938 24.7513 10.4064 25.1601 10.8138C25.5675 11.2226 25.5802 11.8797 25.1867 12.3025L19.5152 17.974L25.1867 23.6455C25.4923 23.9524 25.5844 24.4128 25.4184 24.8132C25.2523 25.2137 24.8617 25.4746 24.4291 25.4746C24.1445 25.4746 23.8724 25.3615 23.6715 25.1607L18 19.4892Z" fill="#2E434D"/>
    </SvgIcon>
  );
};

export default IconClose;
