import color from "@definitions/mui/color";
import { makeStyles } from "@mui/styles";
import { IUseStyles } from "@layouts/Main/lib/Header/interface";
import { fontFamSecondary } from "@definitions/mui/custom";

const useStyles = ({ mdClient }: IUseStyles) => {
  return makeStyles(() => ({
    paper: {
      padding: mdClient ? 16 : "40px 0",
      marginTop: mdClient ? -16 : "unset",
      marginLeft: -16,
      width: "100%",
      maxWidth: "100%",
      height: mdClient ? "100vh" : "unset",
      maxHeight: mdClient ? "100vh" : "unset",
      boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.1)",
      zIndex: 999,
    },
    searchIcon: {
      color: mdClient ? color.general.main : color.primary.main,
      width: 16,
      height: 16,
    },
    searchTextStyled: {
      marginLeft: 10,
      color: color.primary.main,
      display: "block",
    },
    searchBox: {
      "& .MuiInputBase-input": {
        fontFamily: fontFamSecondary,
      },
    },
    paperRoot: {
      zIndex: 999,
    },
    popularSearchText: {
      marginTop: mdClient ? 10 : 8,
      maxWidth: 414,
      color: color.general.main,
      lineHeight: "21px",
      display: "inline-block",
      textDecoration: "underline",
      cursor: "pointer",
      transition: "500ms",
      ...(mdClient && { fontSize: 12, lineHeight: "18px" }),
      "&:hover": {
        color: color.primary.main,
      },
    },
    closeIcon: {
      width: mdClient ? 28 : 48,
      height: mdClient ? 28 : 48,
      position: "absolute",
      top: 0,
      right: 0,
      cursor: "pointer",
      "& > path": {
        fill: mdClient ? color.general.main : color.inactive.secondary,
      },
    },
    containerRow: {
      padding: 0,
      display: "grid",
      gridTemplateColumns: "1fr",
      position: "relative",
    },
  }))();
};
export default useStyles;
