import * as React from "react";
import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const SvgSearchIcon = (props: IconProps) => {
  return (
    <SvgIcon {...props} color="inherit" viewBox="0 0 17 17">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 9.314 2.686 12 6 12C7.31471 12 8.53058 11.5773 9.51913 10.8603C9.56601 10.9544 9.62864 11.0426 9.70705 11.1211L14.6571 16.0711C15.0471 16.4611 15.6811 16.4611 16.0711 16.0711C16.4611 15.6811 16.4611 15.0471 16.0711 14.6571L11.1211 9.70705C11.0427 9.62866 10.9544 9.56604 10.8603 9.51916C11.5773 8.53061 12 7.31472 12 6C12 2.686 9.314 0 6 0C2.686 0 0 2.686 0 6ZM10 6C10 8.21 8.21 10 6 10C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2C8.21 2 10 3.79 10 6Z" fill="currentColor"/>
    </SvgIcon>
  );
};

export default SvgSearchIcon;
