import { AxiosRequestConfig } from "axios";

export const BASE_URL: string = process.env.NEXT_PUBLIC_BASE_URL as string;

export const NavbarServices = {
  GetProductMenu: (): AxiosRequestConfig => ({
    url: `${BASE_URL}/mytens-explore/v2/product-menu?level=0`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
    },
  }),
  GetBusinessSolutionMenu: (): AxiosRequestConfig => ({
    url: `${BASE_URL}/mytens-explore/v2/business-menu`,
    method: "GET",
    headers: {
      Authorization: `Basic ${process.env.NEXT_PUBLIC_BASIC_AUTH}`,
    },
  }),
};
