import React, { useState } from "react";
import { useRouter } from "next/router";
import SearchBox from "@atoms/SearchBox";
import color from "@definitions/mui/color";
import Text from "@ds/components/Text";
import IconClose from "@icons/IconClose";
import SearchIcon from "@icons/Search";
import { Box, Container, Popover, Slide } from "@mui/material";
import popularSearchDev from "../../../../../../../containers/GlobalSearch/popularSearch";
import useAction from "../../../../../_hooks/useAction";
import { IHeaderLPComponentProps } from "../../interface";
import useStyles from "./styles";
import Link from "next/link";
import { useTranslation, withTranslation } from "next-i18next";
import SearchShortIcon from "@icons/mobile/SearchShort";

export const GlobalSearch: React.FC<IHeaderLPComponentProps> = ({
  isDashboardGlobalSearch,
  asInsightList,
}) => {
  const { mdClient } = useAction();
  const [searchPopup, setSearchPopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const classes = useStyles({ mdClient, isVisible: true, isAdmin: false });
  const router = useRouter();
  const { t } = useTranslation("common");

  const showSearchPopup = () => {
    setSearchPopup(true);
  };

  const closeSearchPopup = () => {
    setSearchPopup(false);
  };

  const renderSearchIconButton = (
    <Box
      display="flex"
      alignItems="center"
      style={{
        marginRight: mdClient ? 8 : 0,
        cursor: "pointer",
      }}
      onClick={showSearchPopup}
    >
      {asInsightList ? (
        <SearchShortIcon onClick={showSearchPopup} />
      ) : (
        <SearchIcon onClick={showSearchPopup} className={classes.searchIcon} />
      )}
      {mdClient ? null : (
        <Text variant="body1" className={classes.searchTextStyled}>
          {t("search.title")}
        </Text>
      )}
    </Box>
  );

  const renderPopularSearch = (
    <Box display="flex" flexDirection="column">
      <Text
        weight="700"
        variant={mdClient ? "subtitle1" : "h2"}
        sx={{ ...(mdClient && { fontSize: 14 }) }}
      >
        {t("search.popularLabel")}
      </Text>
      {popularSearchDev.map((item, i) => (
        <Text
          key={i}
          variant={mdClient ? "body2" : "body1"}
          weight="700"
          className={classes.popularSearchText}
          onClick={() => setSearchPopup(false)}
        >
          <Link href={`/${item.url}`}>{item.name}</Link>
        </Text>
      ))}
    </Box>
  );

  const renderSearchBox = (
    <Box sx={{ width: "100%" }}>
      <SearchBox
        style={{
          fontSize: 14,
          height: 36,
          backgroundColor: "#FFF",
          border: "1px solid #DEE3ED",
        }}
        placeholder={t("search.placeholder")}
        onChange={() => undefined}
        onClick={showSearchPopup}
      />
    </Box>
  );

  return (
    <>
      {isDashboardGlobalSearch ? renderSearchBox : renderSearchIconButton}
      <Popover
        open={searchPopup}
        onClose={closeSearchPopup}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorPosition={{ top: mdClient ? 0 : 70, left: -16 }}
        classes={{ paper: classes.paper, root: classes.paperRoot }}
      >
        <Slide direction="down" in={searchPopup}>
          <Container className={classes.containerRow}>
            <IconClose className={classes.closeIcon} onClick={closeSearchPopup} />
            <Text
              weight="700"
              variant="h2"
              sx={{ fontSize: mdClient ? 16 : 32, lineHeight: mdClient ? "26px" : "43px" }}
            >
              {t("search.title")}
            </Text>
            <Text
              variant={mdClient ? "caption1" : "body1"}
              sx={{
                color: color.inactive.secondary,
                display: "block",
                marginTop: 4,
              }}
            >
              {t("search.description")}
            </Text>
            <Box className={classes.searchBox}>
              <SearchBox
                onChange={(val: string) => {
                  setSearchInput(val);
                }}
                onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
                  if (evt.key === "Enter" && searchInput !== "") {
                    setSearchPopup(false);
                    setSearchInput("");
                    router.push(`/search/${searchInput}`);
                  }
                }}
                value={searchInput || ""}
                placeholder={t("search.placeholder")}
                style={{
                  margin: mdClient ? "20px 0" : "40px 0",
                  fontSize: 14,
                  maxWidth: 432,
                  height: 48,
                  backgroundColor: "#FDFDFD",
                  border: "1px solid #DEE3ED",
                }}
              />
            </Box>
            {renderPopularSearch}
          </Container>
        </Slide>
      </Popover>
    </>
  );
};

export default withTranslation("common")(GlobalSearch);
