import callAPI from "@helpers/network";
import { NavbarServices } from "@services/navbar";

export const NAVBAR_API = {
  GET_PRODUCT_MENU: async () => {
    return callAPI(NavbarServices.GetProductMenu());
  },
  GET_BUSINESS_SOLUTION_MENU: async () => {
    return callAPI(NavbarServices.GetBusinessSolutionMenu());
  },
};