import color from "@definitions/mui/color";
import { makeStyles } from "@mui/styles";

const useStyles = () => {
  return makeStyles(() => ({
    root: {
      "&::placeholder": {
        color: color.general.mid,
        opacity: 1,
      },
      "&:focus": {
        borderColor: color.general.main,
      },
      backgroundColor: "#F1F3F7",
      borderRadius: 50,
      boxSizing: "border-box",
      color: color.general.main,
      fontSize: 16,
      height: 40,
      padding: "0px 12px 0px 16px",
      position: "relative",
      width: "100%",
    },
    icon: {
      heihgt: 16,
      marginRight: 12,
      width: 16,
      color: color.general.mid,
    },
    deleteIcon: {
      height: 22,
      width: 22,
      padding: 3,
      borderRadius: 50,
      color: color.general.mid,
      backgroundColor: "#DEE3ED",
      cursor: "pointer"
    }
  }))();
};

export default useStyles;
